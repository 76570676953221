import React from 'react';
import { Helmet } from 'react-helmet';

import * as CONFIG from '../../SiteConfig';

import Layout from '../components/templates/Default';
import PasswordForgetForm from '../components/particles/PasswordForget';

const PasswordForgetPage = () => (
  <>
    <section className="grid">
      <h1>PasswordForget</h1>
      <PasswordForgetForm />
    </section>
  </>
);

export default () => (
  <Layout>
    <Helmet
      title={`${CONFIG.SITE_TITLE} | Forgotten Password`}
      defer={false}
    />
    <PasswordForgetPage />
  </Layout>
);
